import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as commonActionCreators } from "../../store/Common";
import QRCode from "react-qr-code";
import history from "../../utils/history";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import { SubmitContactUs, SubmitPharmacyScript } from "../../api/account";
import { Button } from "../../components/common";
import {
  isNotEmpty,
  isMobile,
  isEmail,
  isAffiliateCodeExists,
  isValidBSB,
  isValidAccountNumber,
  isValidAccountNumberNZ,
} from "../../utils/formik-validate";
import {
  PhoneInput,
  TextInput,
  ImageInput,
  DropdownInput,
} from "../../components/input";
import TextArea from "../../components/input/TextArea/TextArea";
import { ModuleQRcode } from "./Module";
import AddressInput from "../../components/input/AddressInput/AddressInput";
import iconDownload from "../../assets/vetxtend/icons/icon-download-white.png";
import iconEnvelope from "../../assets/vetxtend/icons/icon-envelope-white.png";
import iconLink from "../../assets/vetxtend/icons/icon-link-white.png";
import { toast } from "react-toastify";

const imageBaseUrl: any = `${process.env.REACT_APP_BACKEND_API}${process.env?.REACT_APP_BACKEND_IMAGE_ENDPOINT}`;
const PracticeDetails: React.VFC<any> = () => {
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const common = useSelector((state: ApplicationState) => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState({ modalQR: false });
  return (
    <div className="container" style={{ display: "block" }}>
      <h2 className="title-2" style={{ textAlign: "left" }}>
        Practice Details
      </h2>
      <div style={{ display: "flex", columnGap: "20px", flexWrap: "wrap" }}>
        <div className="details-display">
          <p>
            Here you can view your practice details. Click “Edit Profile” below
            if you need to update any details.
          </p>
          <p>
            <strong>Practice Name:</strong> {""}
            {user?.practice_name} <br />
            <strong>Is your practice part of a coaching group? :</strong> {""}
            {user?.coaching_group} <br />
            <strong>Website:</strong> {""}
            {user?.website} <br />
            <strong>Email:</strong> {""}
            {user?.email} <br />
            <strong>Phone Number:</strong> {""}
            {user?.phone} <br />
            <strong>Address:</strong> {""}
            <span>
              {user?.address?.address2} {user?.address?.address1}, {""}
              {user?.address?.city} {""}
              {user?.address?.province} {""}
              {user?.address?.country} {""}
              {user?.address?.postcode} {""}
            </span>
          </p>
          <p style={{ marginTop: "20px" }}>
            <span className="span-title">CONTACT INFORMATION</span>
            <br />
            <strong>Contact First Name:</strong> {""}
            {user?.contact_firstname} <br />
            <strong>Contact Last Name:</strong> {""}
            {user?.contact_lastname} <br />
            <strong>Job Title:</strong> {""}
            {user?.contact_jobtitle} <br />
            <strong>Email:</strong> {""}
            {user?.contact_email} <br />
          </p>
          <p style={{ marginTop: "20px" }}>
            <span className="span-title">BANK INFORMATION</span>
            <br />
            <strong>Bank Account Name:</strong> {""}
            {user?.bank_account_name} <br />
            <strong>BSB:</strong> {""}
            {user?.bank_account_bsb} <br />
            <strong>Bank Account Number:</strong> {""}
            {user?.bank_account_number} <br />
          </p>
          <p>
            <a
              onClick={() => {
                setIsLoading(true);
                //return console.log({ p, id: p.id });
                if (!user?.practice_id) return;
                //await fetchUserData(p.id);
                changeActionType({
                  type: "edit-practice",
                  id: user?.practice_id,
                });
                return history.push("/dashboard/my-practices");
              }}
              className="btn btn-dark-blue"
              style={{
                textDecoration: "underline",
                display: "inline-block",
              }}
            >
              Edit Profile
            </a>
          </p>
        </div>
        {user?.subscription_plan != "vetxplore" && (
          <div style={{ width: "670px", maxWidth: "100%" }}>
            <ModuleQRcode />
          </div>
        )}
      </div>
      <br />
      <Modal
        maxWidth={800}
        maxHeight={500}
        isOpen={show?.modalQR}
        closeModal={() => {
          setShow({ ...show, modalQR: false });
        }}
      >
        <ModuleQRcode />
      </Modal>
    </div>
  );
};
const ContactUs: React.VFC<any> = () => {
  const user = useSelector((state: ApplicationState) => state.user);
  const [show, setShow] = useState({
    successNote: false,
    errorNote: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userAddress, setUserAddress] = useState({
    practice_name: user?.practice_name ? user?.practice_name : "",
    firstname: user?.contact_firstname ? user?.contact_firstname : "",
    lastname: user?.contact_lastname ? user?.contact_lastname : "",
    contact_email: user?.contact_email ? user?.contact_email : "",
    contact_phone: user?.phone ? user?.phone : "",
    message: "",
  });
  const BasicInformation = useFormik({
    initialValues: {
      ...userAddress,
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      return SubmitContactUs(values)
        .then(async (r: any) => {
          setIsLoading(false);
          const { success } = r.data;
          console.log({ success });
          if (!success)
            return toast.error(`Unable to send inquiry please try again!`);
          return toast.success(`Successfully sent!`);
        })
        .catch((e) => {
          console.log({ e });
          return setShow({ ...show, errorNote: true });
        });
      //console.log({ values });
    },
    validate: async ({
      practice_name,
      firstname,
      lastname,
      contact_email,
      contact_phone,
      message,
    }) => {
      const errors = {
        ...isNotEmpty(practice_name, "practice_name"),
        ...isNotEmpty(firstname, "firstname"),
        ...isNotEmpty(lastname, "lastname"),
        ...isNotEmpty(contact_email, "contact_email"),
        ...isNotEmpty(contact_phone, "contact_phone"),
        ...isNotEmpty(message, "message"),
      };
      return errors;
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    console.log(`re-render`, { show });
  }, [show]);
  return (
    <div style={{ padding: "20px" }}>
      <h2 className="title-2">Contact Us</h2>
      <Row>
        <TextInput
          label="Practice Name"
          value={BasicInformation.values.practice_name}
          error={BasicInformation.errors.practice_name}
          touched={BasicInformation.touched.practice_name}
          onChange={BasicInformation.handleChange("practice_name")}
          required
        />
      </Row>
      <Row>
        <TextInput
          label="First Name"
          value={BasicInformation.values.firstname}
          error={BasicInformation.errors.firstname}
          touched={BasicInformation.touched.firstname}
          onChange={BasicInformation.handleChange("firstname")}
          required
        />
        <TextInput
          label="Last Name"
          value={BasicInformation.values.lastname}
          error={BasicInformation.errors.lastname}
          touched={BasicInformation.touched.lastname}
          onChange={BasicInformation.handleChange("lastname")}
          required
        />
      </Row>
      <Row>
        <TextInput
          label="Contact Email"
          value={BasicInformation.values.contact_email}
          error={BasicInformation.errors.contact_email}
          touched={BasicInformation.touched.contact_email}
          onChange={BasicInformation.handleChange("contact_email")}
          required
        />
        <TextInput
          label="Contact Phone"
          value={BasicInformation.values.contact_phone}
          error={BasicInformation.errors.contact_phone}
          touched={BasicInformation.touched.contact_phone}
          onChange={BasicInformation.handleChange("contact_phone")}
          required
        />
      </Row>
      <div>
        <label
          style={{
            color: "#5067ac",
            fontWeight: "bold",
            display: "block",
            margin: "25px auto 10px",
          }}
        >
          <span style={{ color: "#dd2222" }}>*</span> {""}
          Brief description of your enquiry
        </label>
        <textarea
          style={{
            width: "100%",
            borderColor: "#d6cfe9",
          }}
          rows={5}
          value={BasicInformation.values.message}
          onChange={BasicInformation.handleChange("message")}
          onBlur={(e) => {
            console.log({ e: BasicInformation.errors.message });
            //console.log(e.currentTarget.value);
          }}
        ></textarea>
        {BasicInformation.errors.message && (
          <span className="inputerror">{BasicInformation.errors.message}</span>
        )}
      </div>
      <p>
        <Button
          label="Submit"
          isDisabled={isLoading}
          customClass="button btn btn-dark-blue"
          onClick={BasicInformation.handleSubmit}
        />
      </p>
    </div>
  );
};
const SendPharmacyScript: React.VFC<any> = () => {
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState({
    successNote: false,
    errorNote: false,
  });
  const [userAddress, setUserAddress] = useState({
    practice_name: user?.practice_name ? user?.practice_name : "",
    practice_email: user?.email ? user?.email : "",
    client_firstname: "",
    client_lastname: "",
    client_mobile: "",
    client_email: "",
    client_address: "",
    //pet_name: "",
    //pet_medication: "",
    //pet_num_repeats: "",
    //pet_qty_dispense: "",
    //prescriber_name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    province: "",
    postcode: "",
    message: "",
  });
  const [attachment, setAttachment] = useState<{
    name: string;
    type: string;
    size: string;
    content: any;
  }>();
  const BasicInformation = useFormik({
    initialValues: {
      ...userAddress,
    },
    onSubmit: async (values) => {
      //return console.log({ values });
      //return toast.error("Error in sending script, please try again");
      //return changeActionType(undefined);
      const { address1, address2, city, province, state, postcode, country } =
        values;
      values.client_address = `${address2} ${address1} ${city} ${province} ${state} ${country} ${postcode}`;
      if (!attachment) return toast.error("Missing prescription script!");
      setIsLoading(true);
      //return console.log({ values, attachment });
      return SubmitPharmacyScript(values, attachment)
        .then(async (r: any) => {
          //console.log({ r });
          setIsLoading(false);
          const { success } = r.data;
          console.log({ success });
          if (!success)
            return toast.error("Error in sending script, please try again");
          toast.success("Successfully Sent");
          return changeActionType(undefined);
        })
        .catch((e) => {
          console.log({ e });
          return toast.error("Error in sending script, please try again");
        });
      //console.log({ values });
    },
    validate: async ({
      practice_name,
      practice_email,
      client_firstname,
      client_lastname,
      client_mobile,
      client_email,
      message,
    }) => {
      const errors = {
        ...isNotEmpty(practice_name, "practice_name"),
        ...isEmail(practice_email, "practice_email"),
        ...isNotEmpty(client_firstname, "client_firstname"),
        ...isNotEmpty(client_lastname, "client_lastname"),
        ...isEmail(client_email, "client_email"),
        ...isNotEmpty(client_mobile, "client_mobile"),
        ...isNotEmpty(message, "message"),
        //...isNotEmpty(pet_name, "pet_name"),
        //...isNotEmpty(pet_medication, "pet_medication"),
        //...isNotEmpty(pet_num_repeats, "pet_num_repeats"),
        //...isNotEmpty(pet_qty_dispense, "pet_qty_dispense"),
        //...isNotEmpty(prescriber_name, "prescriber_name"),
      };
      console.log(errors);
      return errors;
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    console.log(`re-render`, { show });
  }, [show]);
  return (
    <div style={{ padding: "20px" }}>
      <h2 className="title-2">Send a pharmacy script</h2>
      <h3
        className="title-2"
        style={{
          padding: "10px",
          background: "#f2f2f2",
        }}
      >
        Practice Information
      </h3>
      <Row>
        <TextInput
          label="Practice Name"
          value={BasicInformation.values.practice_name}
          error={BasicInformation.errors.practice_name}
          touched={BasicInformation.touched.practice_name}
          onChange={BasicInformation.handleChange("practice_name")}
          required
        />
        <TextInput
          label="Email"
          value={BasicInformation.values.practice_email}
          error={BasicInformation.errors.practice_email}
          touched={BasicInformation.touched.practice_email}
          onChange={BasicInformation.handleChange("practice_email")}
          required
        />
      </Row>
      <h3
        className="title-2"
        style={{
          padding: "10px",
          background: "#f2f2f2",
        }}
      >
        Client's Information
      </h3>
      <Row>
        <TextInput
          label="Client First Name"
          value={BasicInformation.values.client_firstname}
          error={BasicInformation.errors.client_firstname}
          touched={BasicInformation.touched.client_firstname}
          onChange={BasicInformation.handleChange("client_firstname")}
          required
        />
        <TextInput
          label="Client Last Name"
          value={BasicInformation.values.client_lastname}
          error={BasicInformation.errors.client_lastname}
          touched={BasicInformation.touched.client_lastname}
          onChange={BasicInformation.handleChange("client_lastname")}
          required
        />
      </Row>
      <Row>
        <TextInput
          label="Client Mobile Number"
          value={BasicInformation.values.client_mobile}
          error={BasicInformation.errors.client_mobile}
          touched={BasicInformation.touched.client_mobile}
          onChange={BasicInformation.handleChange("client_mobile")}
          required
        />
        <TextInput
          label="Client Email Address"
          value={BasicInformation.values.client_email}
          error={BasicInformation.errors.client_email}
          touched={BasicInformation.touched.client_email}
          onChange={BasicInformation.handleChange("client_email")}
          required
        />
      </Row>
      <Row>
        <AddressInput form={BasicInformation} />
      </Row>
      <Row>
        <TextArea
          label="Message"
          rows={5}
          value={BasicInformation.values.message}
          error={BasicInformation.errors.message}
          touched={BasicInformation.touched.message}
          onChange={BasicInformation.handleChange("message")}
        />
      </Row>
      {window.location.host != "app.vetxtend.co.nz" && (
        <p style={{ color: "#505f98", lineHeight: "30px" }}>
          <strong>Note:</strong> As part of our pharmacy licence requirements,
          we also need you to post us your pen-signed original paper scripts
          each month. Please send them to the following reply-paid address. You
          will not incur a postage fee when using this address.
          <br />
          <br />
          <strong>
            PetPA Pharmacy <br />
            Reply Paid 91355 <br />
            Tweed Heads NSW 2485 <br />
          </strong>
        </p>
      )}
      {window.location.host == "app.vetxtend.co.nz" && (
        <p style={{ color: "#505f98", lineHeight: "30px" }}>
          <strong>Note:</strong> We also need you to post us your pen-signed
          original paper scripts each month. Please send them to:
          <br />
          <br />
          <strong>
            PetPA Pharmacy <br />
            Unit 4/25 Maru Street <br />
            Mount Maunganui 3116, New Zealand <br />
          </strong>
        </p>
      )}
      <h3
        className="title-2"
        style={{
          padding: "10px",
          background: "#f2f2f2",
        }}
      >
        Upload Prescriptions
      </h3>
      <p>
        <div className="upload-prescription">
          <input
            type="file"
            onChange={(e: any) => {
              const file = e?.currentTarget?.files[0];
              console.log({ file });
              if (file) {
                return setAttachment(file);
                const reader = new FileReader();
                const { name, type, size } = file;
                reader.readAsBinaryString(file);
                //reader.readAsDataURL(file);
                reader.onloadend = () => {
                  //console.log({ a: reader.result });
                  /*if (reader.result)
                    setAttachment({
                      name,
                      type,
                      size,
                      content: reader?.result,
                    });*/
                };
              }
            }}
          />
        </div>
      </p>
      <p>
        <Button
          label="Submit"
          isDisabled={isLoading}
          customClass="button btn btn-dark-blue"
          onClick={BasicInformation.handleSubmit}
        />
      </p>
    </div>
  );
};
export { PracticeDetails, ContactUs, SendPharmacyScript };
