import "./Header.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/Auth";
import { actionCreators as commonActionCreators } from "../../../store/Common";
//import { actionCreators as cartActionCreators } from "../../../store/Cart";
import { actionCreators as userActionCreators } from "../../../store/User";

import { Modal } from "../../layout";
import Sidebar from "../../app/Sidebar/Sidebar";
import history from "../../../utils/history";
import { MenuItem } from "../../../types/menu";
import {
  ContactUs,
  SendPharmacyScript,
} from "../../../pages/Dashboard/DashboardModule";

import Cart from "../../shop/Cart/Cart";
import logo from "../../../assets/logo.svg";
import headsetIcon from "../../../assets/icons/headset.svg";
import petPlansIcon from "../../../assets/icons/list.svg";
import claimIcon from "../../../assets/icons/claim.svg";
import claimHistoryIcon from "../../../assets/icons/claim-history.svg";
import padlockIcon from "../../../assets/icons/padlock.svg";
import ambulanceIcon from "../../../assets/icons/ambulance.svg";
import shopIcon from "../../../assets/icons/shopHeader.svg";

import homeIcon from "../../../assets/icons/store.svg";
import chatIcon from "../../../assets/icons/chat.svg";
import editIcon from "../../../assets/icons/edit.svg";
import membershipIcon from "../../../assets/icons/membership.svg";
import paymentIcon from "../../../assets/icons/payment.svg";
import arrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import listIcon from "../../../assets/icons/list.svg";
import logoutIcon from "../../../assets/icons/logout-d2.svg";
import vetXtendLogoLight from "../../../assets/images/vetxtend-logo-light.svg";

/**
 * The header of the website./
 */
const Header = () => {
  const { fetchUserData, fetchPractices } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  //const cart = useSelector((state: ApplicationState) => state.cart?.cart);
  //const open = useSelector((state: ApplicationState) => state.cart?.open);
  const user = useSelector((state: ApplicationState) => state.user);
  const common = useSelector((state: ApplicationState) => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState({
    clinics: false,
    contact: false,
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = bindActionCreators(actionCreators, useDispatch());
  /*const { updateCartOpen } = bindActionCreators(
    cartActionCreators,
    useDispatch()
  );*/
  const initial = () => {
    const IniTIAL = user?.practice_name?.toUpperCase().split(" ");
    const fchar = IniTIAL && IniTIAL[0] ? IniTIAL[0]?.charAt(0) : "A";
    const lchar = IniTIAL && IniTIAL[1] ? IniTIAL[1]?.charAt(0) : "Z";
    return `${fchar}${lchar}`;
  };
  const menuItems: MenuItem[] = [
    {
      label: `Home`,
      iconUrl:
        "https://vetxtend.com.au/wp-content/uploads/2024/11/icon-home-2.png",
      onClick: () => {
        return history.push("/dashboard/");
      },
    },
    /*{
      label: `Current Partnership Level`,
      iconUrl:
        "https://vetxtend.com.au/wp-content/uploads/2024/11/icon-partnership-2.png",
      onClick: () => history.push("/onboarding/order-reference"),
    },*/
    {
      label: `Manage My Practices`,
      iconUrl:
        "https://vetxtend.com.au/wp-content/uploads/2024/11/icon-clinics.png",
      onClick: () => {
        setShow({ ...show, clinics: true });
        //return history.push("/dashboard/my-practices");
      },
    },
    {
      label: `Contact Us`,
      iconUrl:
        "https://vetxtend.com.au/wp-content/uploads/2024/11/icon-support.png",
      onClick: () => {
        setShow({ ...show, contact: true });
        //window.open("https://vetxtend.com.au/contact-us/", "_blank");
      },
    },
    {
      label: "Logout",
      iconUrl:
        "https://vetxtend.com.au/wp-content/uploads/2024/11/icon-logout.png",
      onClick: async () => {
        await logout();
      },
    },
  ];
  useEffect(() => {
    (async () => {
      await fetchPractices();
    })();
  }, [isAuthenticated]);
  useEffect(() => {
    (async () => {
      const checkOwnership = user?.practices?.find((f: any) => {
        return f.id == user?.practice_id;
      });
      if (!checkOwnership) await fetchUserData(user?.practices[0]?.id);
      console.log(`HEADER ALWAYS LOADED`, { user });
    })();
  });

  const renderSidebarTrigger = () => {
    return (
      <div
        className="header__sidebartrigger"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="initialIcon">
          <span>{initial()}</span>
          <img src={arrowLeftIcon} />
        </span>
      </div>
    );
  };

  if (!isAuthenticated) return <></>;
  return (
    <header className="header">
      <div className="header__container">
        <div className="container" style={{ paddingTop: "0" }}>
          <div className="flex">
            <Link
              className="header__logo"
              style={{ display: "flex", alignItems: "center" }}
              to="/"
            >
              <img src={vetXtendLogoLight} style={{ height: "25px" }} />
            </Link>
            {renderSidebarTrigger()}
          </div>
          <Sidebar
            isOpen={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            menuItems={menuItems}
          />
        </div>
      </div>
      <Modal
        isOpen={show?.clinics ?? false}
        closeModal={() => {
          return setShow({ ...show, clinics: false });
        }}
        maxWidth={600}
        disablePadding
      >
        <div style={{ padding: "20px" }}>
          {user?.practices && (
            <>
              <p
                style={{
                  marginTop: "0",
                  fontSize: "24px",
                  textAlign: "center",
                  color: "#51429a",
                }}
              >
                Choose Default Practice <br />
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                  onClick={async () => {
                    setShow({ ...show, clinics: false });
                    return history.push("/dashboard/my-practices");
                  }}
                >
                  <img
                    src={listIcon}
                    style={{
                      width: "15px",
                      bottom: "-4px",
                      position: "relative",
                    }}
                  />
                  MANAGE PRACTICES
                </span>
              </p>
              <ul className={isLoading ? `list-block isloading` : `list-block`}>
                {user?.practices?.map((p: any) => {
                  return (
                    <li className={user?.practice_id == p.id ? `active` : ``}>
                      <span className="desc-wrapper">
                        <img
                          className="edit"
                          src="https://vetxtend.com.au/wp-content/uploads/2024/11/icon-edit-2.png"
                          onClick={async () => {
                            setIsLoading(true);
                            //return console.log({ p, id: p.id });
                            if (!p.id) return;
                            //await fetchUserData(p.id);
                            changeActionType({
                              type: "edit-practice",
                              id: p.id,
                            });
                            setIsLoading(false);
                            setShow({ ...show, clinics: false });
                            return history.push("/dashboard/my-practices");
                          }}
                        />
                        <span className="desc">
                          <span className="heading">{p?.name}</span>
                          <i className="location">
                            {p?.address1} {p?.address2} {p?.city}
                            {p?.state} {p?.postcode} {p?.country}
                          </i>
                        </span>
                      </span>
                      <a
                        className="btn btn-dark-blue switch"
                        onClick={async () => {
                          setIsLoading(true);
                          //return console.log({ p, id: p.id });
                          if (!p.id) return;
                          await fetchUserData(p.id);
                          changeActionType(undefined);
                          setIsLoading(false);
                          setShow({ ...show, clinics: false });
                        }}
                      >
                        Switch Practice
                      </a>
                    </li>
                  );
                })}
              </ul>
              <br />
              <a
                style={{ width: "250px" }}
                className="btn btn-dark-blue"
                onClick={async () => {
                  await changeActionType("add-new-practice");
                  setShow({ ...show, clinics: false });
                  return history.push("/onboarding/practice-information");
                }}
              >
                Subscribe New Practice
              </a>
            </>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={common?.actionType == "send-pharmacy-script" ? true : false}
        closeModal={() => changeActionType(undefined)}
        maxWidth={700}
        disablePadding
      >
        <SendPharmacyScript />
      </Modal>
      <Modal
        isOpen={show?.contact ?? false}
        closeModal={() => {
          return setShow({ ...show, contact: false });
        }}
        maxWidth={700}
        disablePadding
      >
        <ContactUs />
      </Modal>
    </header>
  );
};

export default Header;
